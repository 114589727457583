.chatList{
    flex: 1;
    overflow: scroll;
    scrollbar-width: none;
    .search{
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px;

        .searchBar{
            flex: 1;
            /* background-color: rgba(17, 25, 40, 0.5); */
            background: black;
            display: flex;
            align-items: center;
            gap: 20px;
            border-radius: 10px;
            padding: 10px;

            input{
                background-color: transparent;
                border: none;
                outline: none;
                color: white;
                flex: 1;
            }

            img{
                width: 20px;
                height: 20px;
            }

        }

        .add{
            width: 36px;
            height: 36px;
            /* background-color: rgba(17, 25, 40, 0.5) ; */
            background: black;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;
        }
    }

    .item{
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid #dddddd35;
        background-color: #ffffff !important;

        img{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
        }

        .texts{
            display: flex;
            flex-direction: column;
            gap: 10px;

            span{
                font-size: 500;
            }

            p{
                font-size: 14px;
                font-weight: 300; 
            }
        }
    }
    .item.highlight{
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid #dddddd35;
        background-color: #fdf4f3 !important;

        img{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
        }

        .texts{
            display: flex;
            flex-direction: column;
            gap: 10px;

            span{
                font-size: 500;
            }

            p{
                font-size: 14px;
                font-weight: 300; 
            }
        }
    }
}
/* Chat item highlight background */
.highlight {
    background-color: #f0f0f5;
  }
  
  /* Style for new message text */
  .newMessage {
    font-weight: bold;
    color: #da593c; /* Highlight color */
  }
  
  /* Container for avatar and unseen message count badge */
  .avatarContainer {
    position: relative;
    display: inline-block;
  }
  
  /* Unseen message count badge styling */
  .unseenCountBadge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #da593c; /* Badge color */
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 50%;
    padding: 4px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    line-height: 1;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Adjust text alignment and spacing within the chat item */
  .texts {
    margin-left: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  