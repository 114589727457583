.filters_inventory_Rating_review {
  /* margin-top: 9px; */
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  gap: 10px;
}
.filter_btn_invontry_rating_review {
  font-family: jost;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
}
.order_select_tag_rating_review .ant-select-selector {
  background-color: rgba(252, 233, 232, 1) !important;
  border-color: red !important;
  border: none !important;
}
.order_select_tag_rating_review span {
  background-color: rgba(252, 233, 232, 1) !important;
  color: black !important;
  font-size: 14px !important;
}
.order_select_tag_rating_review svg {
  font-size: 10px !important;
}
.order_select_tag_rating_review 
.fa_filter_rating_review {
  margin-right: 20px;
  margin-right: 7px;
  text-align: center;
  align-items: center;
  display: flex;
}
.all_tabs_value_rating_review {
  margin-left: 20px;
}
.orders_now_IoArrow {
  cursor: pointer;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 6px;
  width: 36%;
  height: 33px;
}
@media only screen and (max-width: 768px) {
  .all_tabs_value_rating_review {
    margin-left: 4px;
    display: flex;
    width: 100%;
    overflow: scroll !important;
  }
  .filters_inventory_Rating_review {
    margin-left: 20px;
    margin-bottom: 13px;
  }
}
@media only screen and (max-width: 425px) {
  .all_tabs_value_rating_review button {
    padding: 6px;
    overflow: scroll !important;
  }
  .categories_main_div {
    flex-direction: column;
  }
  .promotions_filters_div {
    gap: 10px;
    margin-top: 10px;
  }
  .filters_inventory_Rating_review {
    gap: 14px;
  }
  .filter_btn_invontry_rating_review {
    font-size: 15px;
  }
  .fa_filter_rating_review {
    margin-right: 0px;
    margin-top: 8px;
  }
}
