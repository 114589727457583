.chat {
    flex: 2;
    border-left: 1px solid #dddddd35;
    border-right: 1px solid #dddddd35;
    height: 100%;
    display: flex;
    flex-direction: column;

    .top{
        padding: 20px;
        display: flex;
        align-items: center;
       justify-content: space-between;
       border-bottom: 1px solid #dddddd35;

       .user{
        display: flex;
        align-items: center;
        gap: 20px;
       }

       img{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover ;
       }

       .texts{
        display: flex;
        flex-direction: column;
        gap: 5px;

        span{
            font-size: 18px;
            font-weight: bold;
        }
        p{
            font-size: 14px;
            font-weight: 300;
            color: #a5a5a5;
        }
       }

       .icons{
        display: flex;
        gap: 20px;
        align-items: center;

        img{
            width: 20px;
            height: 20px;
        }
      }
    } 
    
    .center{
        padding: 20px;
        flex: 1;
        overflow: scroll;
        scrollbar-width: none;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .message{
            max-width: 70%;
            display: flex;
            gap: 20px;

            &.own{
                align-self: flex-end;

                .texts{
                    p{
                        background-color: #fdf4f3;
                    }
                }

            }

            img{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: cover;
            }
            .texts{
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 5px;

                img{
                    width: 100%;
                    height: 300px;
                    border-radius: 10px;
                    object-fit: cover;
                }

                p{
                    padding: 15px;
                    background-color: #fdf4f3;
                    border-radius: 10px;
                }

                span{
                    font-size: 13px;
                }
            }
    }
}

    .bottom{
        padding: 6px 15px 7px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #dddddd35;
        gap: 20px;
        margin-top: auto;

        img{
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .icons{
            display: flex;
            gap: 20px;


        }

        input{
            flex: 1;
            background-color: rgba(107, 109, 111, 0.5);
            border: none;
            outline: none;
            color: white;
            padding: 6px;
            border-radius: 10px;
            font-size: 16px;

            &:disabled{
                
                cursor: not-allowed;
            }
        }

        .emoji{
            position: relative;

            .picker{
                position: absolute;
                bottom: 50px;
                /* left: 0; */
                left: -220px;
            }
        }

        .sendButton{
            background-color: rgba(107, 109, 111, 0.5);
            color: white;
            padding: 7px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;

            &:disabled{
                background-color: #5182feb4;
                cursor: not-allowed;
            }
        }

    }

}
.message_chat_bottom{
    background: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.message_content_for_frisbee p{
margin-bottom: 0px;
}

.singleTick::before {
    content: "✓";
    margin-left: 8px;
    color: #757575;
  }
  
  .doubleTick::before {
    content: "✓✓";
    margin-left: 8px;
    color: #1e90ff; /* Optional blue color for seen messages */
  }