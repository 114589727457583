.categories_tb_select {
  background-color: rgba(252, 233, 232, 1) !important;
  border-color: red !important;
  border: none !important;
  border-radius: 5px;
  padding: 3px;
}
.categories_main_div {
  display: flex;
  justify-content: space-between;
}
/* ///// */
.categories_card_box_for_pet {
  border: 1px solid #ddd;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 0px 10px 0px #c5c5c5;
  margin-bottom: 30px;
  float: left;
  border-radius: 10px;
  background-color: white;
}
.img-fluid {
  width: 100%;
}
.img-fluid {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.card-thumbnail {
  overflow: hidden;
}
.img-fluid:hover {
  transform: scale(1.2);
}
.categories_card_box_for_pet.card-thumbnail:hover {
  transform: scale(1.2);
}
.categories_card_box_for_pet h3 a {
  font-size: 20px;
  text-decoration: none;
}
.cotegories_pet_food {
  font-family: "Jost";
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
.cotegories_about_food_description {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(123, 123, 123, 1);
}
.catogries_create_status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cartegories_created_on {
  font-family: "Jost";
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: rgba(38, 50, 56, 1);
}
.cartegories_created_on_date {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(38, 50, 56, 1);
}
.catogries_create__switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.catogries_product_create__switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  gap: 10px;
}
.product_card_edit_button {
  width: 50%;
}
.button_delete_producttt {
  width: 100%;
}
.button_delete_producttt svg {
  margin-bottom: 3px;
}
.categories_switch_.ant-switch.ant-switch-checked {
  background-color: rgba(48, 194, 35, 1);
}
.categories_switch_.ant-switch.ant-switch-checked:hover {
  background-color: rgba(48, 194, 35, 1);
}
/* // PopUp moudle css */
.categories_popup_title {
  text-align: center;
  /* margin-bottom: 30px;
  margin-top: 20px; */
}
.categories_popup_title_heading {
  font-size: 22px !important;
  font-weight: 600 !important;
  font-family: "Jost" !important;
  line-height: 31px !important;
}
/* .categories_popup_title.css-h93ljk-MuiTypography-root{
    font-family: jost !important;
  } */
.categories_open_popup_form {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  /* margin-bottom: 20px; */
}
.categories_popup_dialog_modal {
  width: 45% !important;
  border-radius: 16px !important;
}
.categories_open_popup_action_btn {
  text-align: end;
  gap: 10px;
  display: flex;
  justify-content: end;
  margin-top: 70px;
  margin-bottom: 10px;
}
.product_open_popup_action_btn {
  text-align: end;
  gap: 10px;
  display: flex;
  justify-content: end;
  margin-top: 20px;
  margin-bottom: 10px;
}
.product_open_popup_action_btn button {
  font-family: jost;
  font-size: 16px;
  font-weight: 600;
  /* line-height: 23px; */
  padding: 7px;
  width: 35%;
}
.categories_open_popup_action_btn button {
  font-family: jost;
  font-size: 16px;
  font-weight: 600;
  /* line-height: 23px; */
  padding: 14px;
  width: 23%;
}
.categories_open_popup_main_contnet {
  width: 100%;
}
.categories_open_popup_label_name {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 5px;
  margin-top: 15px;
}
/* .categories_modal_popUp {
  }
  .categories_palceholder_name {
  } */
.categories_select_tg_enter_product {
  font-size: 14px;
  font-family: "Jost";
  padding: 6px;
}
.categories_select_tg_enter_product:focus {
  border: 1px solid black;
  box-shadow: none;
}
.product_list_popup_status {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.categories_open_popup_label_name_image {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 15px;
  margin-top: 17px;
}
.inventory_popup_create_promocodes_product_img {
  width: 100%;
}
/* .categories_cancel_button{
    background-color: 
    rgba(38, 50, 56, 1);
  } */
.product_transction_main_Card {
  display: flex;
  justify-content: space-between;
}
.product_transction_main_Card span {
  font-size: 18px;
  font-weight: 600;
  line-height: 26.01px;
}
.product_transction_main_Card a {
  font-size: 13px;
  line-height: 18.79px;
  color: black;
  font-weight: 500;
}
.product_deatils_name_list {
  display: flex;
  width: 50%;
}
.product_details_name_list_span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}
.product_details_name_list_span_name {
  width: 50%;
  font-weight: 500;
  font-size: 13px;
  line-height: 18.79px;
  color: rgba(38, 50, 56, 1);
}
.product_details_name_list_span_name_ {
  width: 50%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20.23px;
  color: rgba(123, 123, 123, 1);
}
.product_deatils_name_list_all_content {
  width: 100%;
}
.product_list_card_categories {
  width: 100%;
  padding: 20px;
  border-radius: 20px !important;
  margin-top: 20px;
}
.category_product_list_card_bottom_btn {
  display: flex;
  width: 100%;
  justify-content: end;
}
.admin_prodcut_list_span {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}
.admin_prodcut_list_span_name {
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
}
.product_inventory_edit_upload {
  margin-left: 20px;
}
.product_inventory_edit_upload.ant-upload-select {
  height: 35px !important;
  margin-top: 25px !important;
  border: none !important;
  padding: 19px !important;
  width: 40% !important;
  background-color: rgba(236, 236, 236, 1) !important;
}
.product_inventory_edit_upload:where(
    .css-dev-only-do-not-override-dkbvqv
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  height: 45px !important;
  margin-top: 10px;
}
.admin_product_export_as {
  border: none;
  border-bottom: none !important;
  background-color: #f4f4f4;
}
.admin_product_export_as:hover {
  color: black !important;
  border-color: none !important;
}
.admin_product_export_as:active {
  color: black !important;
  border-color: #f4f4f4 !important;
}
.product_select_tag_rating_review span {
  color: black !important;
  font-size: 14px !important;
}
.product_select_tag_rating_review svg {
  font-size: 10px !important;
}
/* .product_list_upload_top{

} */
.categroies_image_servies {
  width: 36%;
  max-height: 145px;
  object-fit: cover;
  margin: 0px 25%;
}
.read-more {
  color: #dc3545;
  cursor: pointer;
}

.read-less {
  color: #dc3545;
  cursor: pointer;
}
/* .admin_prodcut_list_span img {
  width: 55%;
} */
@media only screen and (max-width: 768px) {
  .categories_popup_dialog_modal {
    width: 80% !important;
  }
  .categories_open_popup_action_btn button {
    font-size: 12px;
    width: 100%;
  }
  .categories_open_popup_action_btn {
    margin-top: 18px;
  }
  .product_open_popup_action_btn {
    margin-top: 18px;
  }
  .product_open_popup_action_btn button {
    font-size: 12px;
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .categories_popup_title {
    margin-bottom: 14px;
    margin-top: 15px;
  }
  .categories_popup_title_heading {
    font-size: 18px !important;
  }
  .categories_popup_dialog_modal {
    width: 80% !important;
  }
  .categories_open_popup_action_btn button {
    width: 100%;
    font-size: 12px;
    padding: 10px;
  }
  .categories_open_popup_action_btn {
    margin-top: 20px;
  }
  .request_new_categories {
    font-size: 10px;
  }
  .product_open_popup_action_btn button {
    width: 100%;
    font-size: 12px;
    padding: 10px;
  }
  .product_open_popup_action_btn {
    margin-top: 20px;
  }
}
