.order_now_himalays {
  text-decoration: none !important;
  font-size: 15px;
  font-family: jost;
  line-height: 21px;
  font-weight: 400;
}
.orders_now_image img{
  max-width: 120px;
}
.order_now_qty {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  margin: 10px 0px;
}
.orders_now_table tr {
  border-bottom: 2px solid #eee;
}
.orders_now_billing_details_heading {
  font-family: "Jost";
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin-left: 6px;
}
.orders_now_subtotal {
  font-family: "Jost";
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
}
.order_now_rupees {
  font-family: "Jost";
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}
.order_now_address {
  display: flex;
  flex-direction: column;
  padding: 7px;
}
.order_now_address span {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(85, 85, 85, 1);
}
.order_now_card_tilte {
  margin-bottom: 30px;
  margin-top: 10px;
  margin-top: 2%;
}
.order_now_address_date_and_time {
  margin-left: 8px;
  margin-top: 8px;
}
.order_now_address_date_and_time span {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(85, 85, 85, 1);
}
.order_now_date_and_time_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.order_now_heading_button {
  margin-top: 15px;
}
.order_now_heading_button1 {
  background-color: rgba(236, 245, 238, 1);
  color: rgba(44, 200, 74, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  margin-right: 15px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.order_now_heading_button1 svg {
  margin-bottom: 2px;
}
.order_now_heading_button2 {
  background-color: rgba(255, 242, 243, 1);
  color: rgba(209, 26, 42, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.order_now_heading_button2 svg {
  margin-bottom: 2px;
}
.order_now_billing_details_border {
  border-bottom: 2px solid #eee;
}
.orders_now_table tbody tr:nth-child(odd) td {
  background-color: #fff !important;
}
.orders_now_table>tbody>tr:hover>* {
  background-color: unset !important;
}
.orders_now_table td{
  border: none !important;
}
@media only screen and (max-width: 500px) {
  .order_now_heading_button1 {
    margin-bottom: 10px;
  }
}
